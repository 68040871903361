.banner
    width: 100%
    max-width: 2000px
    position: fixed
    top: 0
    left: 50%
    transform: translateX(-50%)
    cursor: pointer
    z-index: 999
    .counter
        position: absolute
        left: 49%
        top: 50%
        width: 25vw
        max-width: 520px
        height: 5vw
        max-height: 110px
        transform: translate(-50%, -50%)
        display: flex
        justify-content: center
        align-items: center
        +mob
            left: initial
            right: 0
            width: 51vw
            transform: translate(0, -50%)
            height: 100%
        &-group
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            margin-right: 0.9vw
            +mob
                margin-right: 1.2vw
            +pc
                margin-right: 16px
            .digit
                color: $color-white
                font-size: 3vw
                +mob
                    font-size: 6vw
                +pc
                    font-size: 46px
            span
                color: white
                font-size: 1vw
                line-height: 14px
                opacity: 0.5
                +mob
                    font-size: 2.3vw
                +pc
                    font-size: 12px
    .sale
        width: 100%
        +mob
            display: none
        &.small
            display: none
            +mob
                display: block
.page-main
    position: relative
    //top: 6.25vw
    //+pc
    //    top: 125px
    //+mob
    //    top: 22vw

    .block.header
        position: relative
        background: $color-brand
        padding: 0 4vw 0 9.6vw
        align-items: center
        color: $color-white
        width: 100%
        height: 41.4vw
        overflow: hidden
        +pc
            max-height: 828px
            padding: 0 80px 0 192px
        +tab
            height: initial
            padding: 30px 20px 0 20px
            flex-direction: column
        +mob
            height: initial
            padding: 30px 20px 0 20px
            flex-direction: column
        &:before
            content: ''
            display: block
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 50px
            background: url("/assets/cover_header.png") no-repeat 87% 100% / 44%
            z-index: 0
            +tab
                display: none
            +mob
                display: none
        &:after
            content: ''
            display: block
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 0
            background: url("/assets/cover_bg.svg") no-repeat 50% 100% / cover
            z-index: 1
            pointer-events: none
            +tab
                display: none
            +mob
                display: none
        .actions
            position: absolute
            bottom: 5vw
            +pc
                bottom: 100px
            +tab
                position: relative
                bottom: initial
            +mob
                position: relative
                bottom: initial
        a
            position: relative

            &.pc
                +tab
                    display: none
                +mob
                    display: none
        .button
            padding: 1.3vw 2.7vw
            +pc
                padding: 26px 54px
            +tab
                padding: 24px 48px
                //width: 100%
            +mob
                padding: 8px 24px
                width: 100%
        .info
            display: none
            padding-top: 3vw
            padding-bottom: 6vw
            width: 50%
            +tab
                display: block
                width: 100%
                padding: 0
                margin-bottom: 40px
            +mob
                display: block
                width: 100%
                padding: 0
                margin-bottom: 40px
            .logo
                margin-bottom: 6vw
                +tab
                    height: 40px
                +mob
                    height: 25px
            h1
                img
                    +tab
                        height: 45px
                    +mob
                        height: 26px
            span
                margin-bottom: 2vw
                +tab
                    margin-bottom: 37px
                    max-width: 600px
                +mob
                    margin-bottom: 37px
        .visual
            display: none
            width: 50%
            position: relative
            +tab
                display: block
                width: 100%
                padding: 0
                text-align: center
            +mob
                display: block
                width: 100%
                padding: 0
            img
                max-width: 100%
                position: relative
                z-index: 1
                +tab
                    top: 4px
                +mob
                    top: 4px
                &.cover
                    position: absolute
                    left: 52%
                    top: 50%
                    transform: translate(-50%, -50%)
                    z-index: 0
                    +tab
                        top: initial
                        bottom: 0
                        transform: translateX(-50%)
                        max-width: 107%
                        max-height: 100%
                    +mob
                        top: initial
                        bottom: 0
                        transform: translateX(-50%)
                        max-width: 107%

    .block.numbers
        flex-direction: column
        justify-content: flex-start
        .visual
            display: flex
            gap: 75px
            margin-top: 120px
            +tab
                margin-top: 60px
                flex-direction: column-reverse
            +mob
                margin-top: 60px
                flex-direction: column-reverse
            .img
                position: relative
                width: 32%
                flex-grow: 1
                +tab
                    width: 100%
                    height: 60px
                +mob
                    width: 100%
                    height: 60px
                img
                    position: absolute
                    left: -65%
                    bottom: -70%
                    width: 150%
                    z-index: 1
                    +tab
                        max-width: 100%
                        left: 50%
                        bottom: -124px
                        transform: translateX(-50%)
                        width: 300px
                    +mob
                        max-width: 100%
                        left: 50%
                        bottom: -124px
                        transform: translateX(-50%)
                        width: 80%
            .text
                width: 70%
                flex-grow: 1
                +tab
                    width: 100%
                +mob
                    width: 100%
                span:not(:last-child)
                    margin-bottom: 24px

    .block.impact
        background-color: $color-light-blue
        text-align: center
        flex-direction: column
        align-items: center
        h2
            max-width: 922px
            margin-bottom: 70px
        h3
            margin-bottom: 16px
            +tab
                margin-bottom: 10px
            +mob
                margin-bottom: 10px
        .button
            margin-top: 80px
            +tab
                margin-top: 40px
            +mob
                margin-top: 40px

    .block.how
        flex-direction: column
        position: relative
        background-color: white
        padding-top: 60px
        h2
            +tab
                font-size: 48px

        h3
            margin-bottom: 16px
            +mob
                font-size: 16px
                line-height: 24px
                display: none
        a
            margin-top: 32px

        span
            &.small
                font-size: 20px
                line-height: 32px
                display: inline
                +tab
                    font-size: 16px
                    line-height: 24px
                +mob
                    font-size: 16px
                    line-height: 24px

                &.mobile-only
                    display: none
                    +mob
                        display: inline

        .header
            background: linear-gradient(to bottom, white 60%, rgba(255, 255, 255, 0) 100% )
            //position: sticky
            width: 100%
            top: 0
            height: 150px
            display: flex
            justify-content: center
            z-index: 100
            margin-bottom: 100vh
            bottom: 100vh
            padding-top: 60px
            +mob
                background: none
                position: relative
                height: auto
                margin-bottom: 20px
                bottom: 0
                padding-top: 0

        .mock-content
            height: 100vh
            display: flex
            align-items: center
            justify-content: center
            background-color: #f3f3f3
            padding: 2rem

        .scroll-container
            display: flex
            flex-direction: column
            position: relative

        .scroll-section
            margin-top: -100vh
            display: flex
            position: relative
            +mob
                margin-top: 0

        .text-content
            width: 32%
            +tab
                width: 40%
            +mob
                width: 100%

        .paragraph
            height: 100vh
            display: flex
            flex-direction: column
            justify-content: center
            opacity: 0
            +mob
                height: auto
                margin-bottom: 5rem

        .laptop-container
            width: 75%
            position: sticky
            top: 0
            height: 100vh
            display: flex
            align-items: center
            justify-content: center
            +tab
                width: 60%
            +mob
                display: none

        .laptop
            max-width: 100%

        .laptop-content
            position: absolute
            max-width: 100%
            opacity: 0
            transition: opacity 0.5s ease

        .mob-laptop-content
            display: none
            transform: scale(1.075)
            +mob
                display: block
                margin-bottom: 2rem

    .block.plans
        flex-direction: column
        align-items: center
        padding-left: 0
        padding-right: 0
        +tab
            margin-top: 40px
        +mob
            margin-top: 40px
        .bubble
            position: absolute
            width: 420px
            top: -135px
            left: 50%
            transform: translateX(-50%)
            +tab
                width: 188px
                top: -67px
            +mob
                width: 188px
                top: -67px
            img
                max-width: 100%
        h2
            margin-bottom: 100px
            +tab
                text-align: center
                max-width: 700px
                margin-bottom: 40px
            +mob
                text-align: center
                max-width: 350px
                margin-bottom: 40px

        .wrapper
            overflow: hidden
            padding: 0 9.2vw
            width: 100%

        .plans-slider
            width: 100%
            height: auto
            overflow: visible
            .plans-pagination
                display: none
                +tab
                    display: flex
                    justify-content: center
                    margin-top: 20px
                +mob
                    display: flex
                    justify-content: center
                    margin-top: 20px
                .swiper-pagination-bullet
                    width: 10px
                    height: 10px
                    text-align: center
                    line-height: 20px
                    font-size: 12px
                    opacity: .2
                    background: $color-brand
                .swiper-pagination-bullet-active
                    opacity: 1
            .plan
                background: $color-light-blue
                width: 38%
                height: initial
                padding: 40px
                border-radius: 30px
                display: flex
                flex-direction: column
                align-items: flex-start
                justify-content: flex-start
                +tab
                    padding: 30px
                    border-radius: 20px
                    min-width: 460px
                +mob
                    padding: 30px
                    border-radius: 20px
                &.border
                    border: 4px solid $color-brand
                    +tab
                        border-width: 3px
                    +mob
                        border-width: 3px
                .subtitle
                    margin: 0
                    border-top: 1px solid gray
                    width: 100%
                    padding: 15px 0
                >span
                    margin-top: 16px
                    margin-bottom: 32px
                    //flex-grow: 1
                    +tab
                        margin-bottom: 24px
                    +mob
                        margin-bottom: 24px
                ul
                    flex-grow: 1
                    li
                        position: relative
                        padding-left: 40px
                        font-size: 20px
                        font-weight: 500
                        line-height: 32px
                        +tab
                            font-size: 16px
                            line-height: 24px
                        +mob
                            font-size: 16px
                            line-height: 24px
                        &:not(:last-child)
                            margin-bottom: 16px
                            +tab
                                margin-bottom: 8px
                            +mob
                                margin-bottom: 8px
                        &:before
                            content: ''
                            display: block
                            position: absolute
                            left: 0
                            top: 0
                            width: 32px
                            height: 32px
                            background: url("/assets/icon-check.svg") no-repeat 50% 50% / cover
                            +tab
                                width: 24px
                                height: 24px
                            +mob
                                width: 24px
                                height: 24px
                        &.cross
                            color: $color-gray
                            &:before
                                background: url("/assets/icon-cross.svg") no-repeat 50% 50% / cover
                .price
                    position: relative
                    color: $color-brand
                    font-size: 48px
                    font-weight: 700
                    margin: 32px 0
                    margin-top: 16px
                    +tab
                        font-size: 32px
                        line-height: 40px
                        margin: 24px 0
                        margin-top: 16px
                    +mob
                        font-size: 32px
                        line-height: 40px
                        margin: 24px 0
                        margin-top: 12px
                    &.sale
                        text-decoration: line-through
                        font-size: 32px
                        font-weight: 500
                        line-height: normal
                        color: #959595
                        margin-top: 32px
                        margin-bottom: 0
                        +tab
                            margin-top: 24px
                        +mob
                            margin-top: 24px
                            font-size: 20px
                            font-weight: 500
                            line-height: 32px
                        .label
                            position: absolute
                            top: -35%
                            left: 110%
                            background: url(/assets/icon-sale-star.svg) no-repeat 50% 50% / cover
                            height: 60px
                            width: 60px
                            display: flex
                            justify-content: center
                            align-items: center
                            font-size: 16px
                            font-weight: 600
                            color: $color-white
                            z-index: -1
                            +mob
                                top: -25%
                                height: 45px
                                width: 45px
                                font-size: 12px
                .button.go
                    width: 100%
                    +tab
                        width: 100%
                    +mob
                        width: 100%





    .block.reviews
        background-color: $color-light-orange
        flex-direction: column
        align-items: flex-start
        padding-right: 0
        overflow: hidden
        +tab
            align-items: center
            padding-top: 0
        +mob
            align-items: center
            padding-top: 0
        .top
            width: 100%
            padding-right: 9.2vw
            +tab
                padding-right: 20px
                display: flex
                flex-direction: column
                align-items: center
                text-align: center
            +mob
                padding-right: 20px
                display: flex
                flex-direction: column
                align-items: center
                text-align: center
            .bubble
                position: absolute
                width: 450px
                top: 0
                left: 50%
                transform: translateX(-27%)
                +tab
                    position: relative
                    width: 208px
                    left: initial
                    transform: initial
                    margin-top: -20px
                +mob
                    position: relative
                    width: 208px
                    left: initial
                    transform: initial
                    margin-top: -20px
                img
                    max-width: 100%
            h2
                max-width: 581px
                color: $color-black
                +tab
                    margin-bottom: 20px
                    max-width: 670px
                +mob
                    margin-bottom: 20px
            a
                display: block
                margin-bottom: 40px
                +tab
                    margin-bottom: 20px
                +mob
                    margin-bottom: 20px

            .wrapper
                display: flex
                justify-content: space-between
                align-items: center
                width: 100%
                margin-bottom: 40px
                +tab
                    margin-bottom: 20px
                +mob
                    margin-bottom: 20px
                span
                    max-width: 760px
                .buttons
                    +tab
                        display: none
                    +mob
                        display: none
                    .button
                        &:first-child
                            margin-right: 16px
                        &.swiper-button-disabled
                            cursor: initial
                            pointer-events: none
                            opacity: .5
        .reviews-slider
            width: 100%
            height: auto
            overflow: visible
            padding-right: 40px
            .reviews-pagination
                display: none
                +tab
                    display: flex
                    justify-content: center
                    margin-top: 20px
                +mob
                    display: flex
                    justify-content: center
                    margin-top: 20px
                .swiper-pagination-bullet
                    width: 10px
                    height: 10px
                    text-align: center
                    line-height: 20px
                    font-size: 12px
                    opacity: .2
                    background: $color-brand
                .swiper-pagination-bullet-active
                    opacity: 1
            .swiper-slide
                background: $color-white
                width: 38%
                height: initial
                padding: 40px
                border-radius: 30px
                display: flex
                flex-direction: column
                align-items: flex-start
                justify-content: flex-start
                +tab
                    padding: 30px
                    border-radius: 20px
                +mob
                    padding: 30px
                    border-radius: 20px
                .stars
                    img
                        +tab
                            width: 24px
                        +mob
                            width: 24px
                .info
                    display: flex
                    flex-direction: column
                    flex-grow: 1
                    span
                        &:first-child
                            margin: 32px 0 40px 0
                            flex-grow: 1
                            +tab
                                margin: 24px 0
                            +mob
                                margin: 24px 0
                        &.link
                            a
                                color: $color-brand

    .block.faq
        gap: 75px
        +tab
            flex-direction: column
            align-items: center
            gap: initial
        +mob
            flex-direction: column
            align-items: center
            gap: initial
        h2
            width: 32%
            flex-grow: 1
            +tab
                text-align: center
            +mob
                text-align: center
        .table
            width: 70%
            flex-grow: 1
            +tab
                width: 100%
            +mob
                width: 100%
            .row
                padding: 40px 0
                border-top: 1px solid $color-border
                border-bottom: 1px solid $color-border
                +tab
                    padding: 30px 0
                +mob
                    padding: 30px 0
                &:first-child
                    border-top: initial
                .question
                    display: flex
                    justify-content: space-between
                    align-items: flex-start
                    cursor: pointer
                    h3
                        width: 80%
                    .trigger
                        width: 32px
                        height: 32px
                        position: relative
                        cursor: pointer
                        transition: $transitionNorm opacity
                        background: none
                        border: none
                        +tab
                            width: 24px
                            height: 24px
                        +mob
                            width: 24px
                            height: 24px
                        .line
                            width: 100%
                            height: 2px
                            background: $color-brand
                            position: absolute
                            transition: $transitionNorm transform
                            transform: translateZ(0)
                            &.hor
                                top: 50%
                                left: 0
                                transform: translateY(-50%)
                            &.ver
                                top: 50%
                                left: 0
                                transform: translateY(-50%) rotate(90deg)
                .answer
                    overflow: hidden
                    span
                        margin-top: 24px
                &.active
                    .question
                        .trigger
                            .line
                                &.hor
                                    transform: rotate(180deg)
                                &.ver
                                    transform: rotate(180deg)
