.panels
    display: flex
    gap: 40px
    flex-wrap: wrap
    &.extragap
        gap: 75px
        +tab
            gap: 50px
        +mob
            gap: 40px
        &.double
            gap: 75px 200px
            +mob
                gap: 40px
        &.col2
            .panel
                width: 40%
                +mob
                    width: 100%
        &.col3
            .panel
                width: 25%
                +mob
                    width: 100%
    &.col2
        .panel
            width: 45%
            flex-grow: 1
            +mob
                width: 100%
    &.col3
        .panel
            width: 30%
            flex-grow: 1
            +mob
                width: 100%
    &.shifted
        .panel
            &:nth-child(2n)
                position: relative
                top: 176px
                +tab
                    top: 100px
                +mob
                    top: 0
    .panel
        background-color: initial
        border-radius: initial
        overflow: initial
        +tab
            border-radius: 24px
        +mob
            border-radius: 12px
        &.filled
            background-color: $color-white
            border-radius: 30px
            padding: 40px
            overflow: hidden
            &.blue
                background-color: $color-light-blue
        .part
            padding: 40px 40px 48px 40px
            +tab
                padding: 24px 24px 32px 24px
            +mob
                padding: 12px 12px 18px 12px
            &.img
                background-size: cover
                background-position: 50% 50%
            &.pattern
                background: $color-white url('/assets/pattern.svg') no-repeat 100% 50% / auto 100%
        > img
            width: 100%
            margin-bottom: 32px
            +tab
                margin-bottom: 24px
            +mob
                margin-bottom: 18px
