$color-white: #FFFFFF
$color-black: #2A2A2B
$color-brand: #4095F2
$color-light-orange: #FFECCE
$color-light-blue: #F2F8FF
$color-bg-gray: #FCFCFC
$color-gray: #CACACA
$color-border: #DBDBDB
$color-fail: #F56D6D
$color-success: #2AB8AF

$transitionNorm: 400ms ease-in-out