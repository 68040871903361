.block
    width: 100%
    position: relative
    padding: 120px 9.2vw
    display: flex
    +tab
        padding: 60px 40px
    +mob
        padding: 60px 20px

    .close
        position: absolute
        top: 20px
        right: 20px
        z-index: 9
        width: 40px
        height: 40px
        border-radius: 12px
        +tab
            width: 40px
            height: 40px
            border-radius: 8px
        +mob
            top: 16px
            right: 16px
            width: 32px
            height: 32px
            border-radius: 8px
        img
            width: 16px
            height: 16px
            transition: 400ms ease-in-out transform
            +tab
                width: 11px
                height: 11px
            +mob
                width: 11px
                height: 11px
        &:hover
            img
                transform: rotate(90deg)
    &.order
        background: rgb(0 0 0 / 65%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 9999
        overflow-y: scroll
        &.active
            display: flex
        .wrapper
            position: absolute
            top: 0
            left: 0
            width: 100%
            min-height: 100%
            display: flex
            justify-content: center
            align-items: center
        .modal
            position: relative
            display: flex
            flex-direction: column
            justify-content: flex-start
            max-width: 1480px
            width: 1480px
            padding: 60px 128px
            background: $color-white
            border-radius: 40px
            +tab
                padding: 60px 40px
            +mob
                flex-direction: column
                align-items: flex-start
                padding: 40px 20px

            .order-form
                display: flex
                gap: 0 128px
                justify-content: space-between
                +tab
                    gap: 0 45px
                    align-items: flex-start
                +mob
                    gap: 10px 0
                    flex-direction: column
                    align-items: flex-start
                .field
                    position: relative
                    border: 1px solid $color-border
                    border-radius: 16px
                    width: 100%
                .details
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    flex-grow: 1
                    .inner
                        width: 100%
                .review
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    width: 450px
                    min-width: 450px
                    +tab
                        flex-direction: column-reverse
                        width: 290px
                        min-width: 290px
                    +mob
                        flex-direction: column-reverse
                        width: 100%
                        min-width: 100%
                    .sep
                        width: 100%
                        height: 1px
                        background: $color-border
                        margin: 30px 0
                        &-top
                            margin-bottom: 0
                            +tab
                                display: none
                            +mob
                                display: none
                        &-mob
                            display: none
                            +tab
                                display: none
                            +mob
                                display: none
                    .calltoaction
                        width: 100%
                        .legal
                            font-size: 13px
                            font-weight: 600
                            line-height: 20px
                            text-align: center
                            a
                                color: $color-brand
                    .info
                        width: 100%
                        .promocode
                            position: relative
                            margin-top: 16px
                            input
                                margin: 0
                                text-transform: uppercase
                                &::placeholder
                                    text-transform: none
                            .trigger
                                position: absolute
                                display: none
                                top: 15px
                                right: 15px
                                width: 40px
                                height: 40px
                                border-radius: 12px
                                +mob
                                    top: 5px
                                    right: 5px
                                &.active
                                    display: flex
                                img
                                    width: 16px
                                    height: 16px
                            .status
                                color: $color-fail
                                margin: 0
                                font-size: 16px
                                font-weight: 500
                                line-height: 15px
                                padding-left: 30px
                                display: none
                                margin-top: 10px
                                +mob
                                    min-height: 24px
                                &.active
                                    display: block
                                &.success
                                    color: $color-success
                                .highlight
                                    display: inline
                                    color: $color-brand
                                    cursor: pointer
                                    &:hover
                                        opacity: .7
                        .line
                            display: flex
                            justify-content: space-between
                            align-items: flex-start
                            &-promo
                                display: none
                                &.active
                                    display: flex
                            .position
                                font-size: 20px
                                font-weight: 500
                                line-height: 30px
                                max-width: 350px
                                +mob
                                    font-size: 16px
                                &.price
                                    font-weight: 600
                                    max-width: initial
                                &.discount
                                    color: $color-success

                        .detalization
                            display: flex
                            flex-direction: column
                            width: 100%
                            gap: 13px 0

                        .total
                            width: 100%
                            .oldprice
                                font-size: 20px
                                font-weight: 500
                                line-height: 24px
                                color: $color-gray
                                text-align: right
                                text-decoration: line-through
                                display: none
                                &.active
                                    display: block

                            .line
                                .position, .price
                                    font-size: 24px
                                    font-weight: 600
                                    line-height: 40px



            span
                margin: 24px 0
            .subtitle
                +mob
                    font-weight: 600
            input, textarea
                height: 70px
                outline: none
                border-radius: 16px
                border: 1px solid $color-border
                padding: 10px 30px
                font-size: 20px
                font-weight: 500
                line-height: 40px
                width: 100%
                margin-bottom: 24px
                +mob
                    font-size: 16px
                    line-height: 24px
                    height: 50px
            textarea
                height: 120px
                max-width: 100%
                min-width: 100%
                margin-bottom: 0
                font-size: 20px
                font-weight: 500
                line-height: 25px
                font-family: Arial
                padding-top: 18px
            .alien
                position: relative
                margin-bottom: 24px
            .gift
                position: relative
            .checkbox
                position: absolute
                z-index: -1
                opacity: 0
            .checkbox+label
                display: inline-flex
                align-items: center
                font-size: 20px
                font-weight: 500
                line-height: 48px
                width: 100%
                padding: 10px 30px
                cursor: pointer
                +mob
                    font-size: 16px
                    line-height: 24px
            .checkbox+label::before
                content: ''
                display: inline-block
                position: relative
                top: 1px
                width: 25px
                height: 25px
                flex-shrink: 0
                flex-grow: 0
                border: 1px solid $color-border
                border-radius: 6px
                margin-right: 16px
                background-color: $color-white
                background-repeat: no-repeat
                background-position: center center
                background-size: auto
                cursor: pointer
                transition: $transitionNorm background-color

            .checkbox:checked+label::before
                background-color: $color-white
                background-image: url('/assets/icon-check.svg')
                background-size: 90%
            .gift-wrapper
                display: none
                margin-top: 24px
                &.active
                    display: block

            .button
                margin-top: 30px
                margin-bottom: 16px
                width: 100%
                padding: 20px 48px
                +mob
                    padding: 8px 48px