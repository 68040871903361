=mob
    @media (max-width: 767px)
        @content

=tab
    @media (min-width: 768px) and (max-width: 1439px)
        @content

=pc
    @media (min-width: 2000px)
        @content

