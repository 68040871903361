.trigger
    margin-left: 16px
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    background-color: $color-brand
    border: 1px solid $color-brand
    border-radius: 16px
    transition: $transitionNorm opacity, $transitionNorm background-color
    cursor: pointer
    +mob
        width: 40px
        height: 40px
        border-radius: 16px
    &:hover
        opacity: 0.7
    img
        display: none
        width: 32px
        height: 32px
        &:first-child
            display: initial
        +mob
            width: 24px
            height: 24px
    &.filled
        background-color: initial
        display: flex !important
        img
            display: none
            &:last-child
                display: initial

.switch
    margin-left: 16px
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    border: 1px solid $color-brand
    border-radius: 16px
    transition: $transitionNorm transform, $transitionNorm background-color
    cursor: pointer
    +mob
        width: 40px
        height: 40px
        border-radius: 16px
    img
        width: 32px
        height: 32px
        +mob
            width: 24px
            height: 24px
.button
    display: inline-flex
    padding: 24px 48px
    justify-content: center
    align-items: center
    border-radius: 16px
    background: $color-brand
    color: $color-white
    font-size: 24px
    font-weight: 700
    line-height: 40px
    letter-spacing: 0.24px

    transition: $transitionNorm opacity, $transitionNorm background-color
    cursor: pointer
    user-select: none
    +tab
        padding: 24px 48px
        font-size: 24px
        line-height: 40px
        border-radius: 16px
    +mob
        padding: 8px 24px
        font-size: 16px
        line-height: 40px
        border-radius: 12px
    svg
        path
            transition: $transitionNorm stroke
    &:hover
        opacity: 0.7
    &:active
        opacity: 0.6
    &.white
        color: $color-brand
        background: $color-white
    &.hollow
        color: $color-brand
        background: none
    &.disabled
        opacity: .7
        pointer-events: none
        background: $color-gray
    &.icon
        svg
            position: relative
            left: 3px
            path
                stroke: $color-brand
        &.left
            transform: rotate(180deg)
    &.round
        border-radius: 100px
        width: 80px
        height: 80px
        padding: 0
        background: $color-white
        &:hover
            background: $color-brand
            opacity: 1
            svg
                path
                    stroke: $color-white
