.badge
    display: flex
    justify-content: center
    align-items: center
    font-size: 32px
    font-weight: 700
    line-height: 40px
    color: $color-brand
    border-radius: 24px
    background-color: $color-white
    width: 80px
    height: 80px
    margin-bottom: 32px
    +tab
        width: 50px
        height: 50px
        margin-bottom: 24px
        font-size: 24px
        line-height: 32px
        border-radius: 18px
    +mob
        width: 50px
        height: 50px
        margin-bottom: 30px
        font-size: 20px
        line-height: normal
        border-radius: 16px
    img
        width: 56px
        height: 56px
        +tab
            width: 35px
            height: 35px
        +mob
            width: 20px
            height: 20px
    &.border
        border: 2px solid $color-brand