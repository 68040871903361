.page-status
    .block.loading
        position: fixed
        width: 100vw
        height: 100vh
        top: 0
        left: 0
        background: $color-white
        display: none
        justify-content: center
        align-items: center
        overflow: hidden
        z-index: 9
        &.active
            display: flex
        img
            width: 50px
    .block.success
        padding: 0 20px
        display: none
        position: relative
        background: $color-white
        width: 100%
        text-align: center
        &.active
            display: flex
        h1
            color: $color-brand
        .status
            display: none
            position: relative
            flex-direction: column
            justify-content: center
            align-items: center
            width: 100%
            min-height: 100vh
            top: -20px
            &.active
                display: flex
            .bubble
                height: 200px
            .button
                padding: 20px 40px
                +mob
                    padding: 8px 27px
            .primary
                padding: 40px 0
                img
                    width: 50px
            .secondary
                max-width: 810px
            .email
                display: inline
                color: #4095F2
            .info
                opacity: .7
                margin-top: 20px
                max-width: 550px
            &.waiting
                .secondary
                    max-width: 720px
            &.gift
                .intro
                    max-width: 700px
    .block.fail
        padding: 0 20px
        display: flex
        position: relative
        background: $color-white
        width: 100%
        text-align: center
        h1
            color: $color-fail
        .status
            position: relative
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            width: 100%
            min-height: 100vh
            top: -20px
            .bubble
                height: 200px
            .button
                padding: 20px 40px
                +mob
                    padding: 8px 27px
            .primary
                padding: 40px 0
                img
                    width: 50px
            .secondary
                max-width: 810px
            .email
                display: inline
                color: #4095F2
            .info
                opacity: .7
                margin-top: 20px
                max-width: 550px

    .footer
        display: none
        &.active
            display: flex