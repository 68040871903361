@import 'mixin'
@import 'variables'

//fonts imported from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap')

*, *:before, *:after
    box-sizing: border-box

*:focus
    outline: none

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
    margin: 0
    padding: 0
    border: 0
    font-size: 100%
    font: inherit
    user-select: none
    -webkit-user-select: none
    vertical-align: baseline
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    text-size-adjust: none
    -webkit-tap-highlight-color: transparent

footer, header, nav, section, main
    display: block

body
    line-height: 1
    user-select: none

ol, ul
    list-style: none

blockquote, q
    quotes: none

blockquote:before, blockquote:after, q:before, q:after
    content: ''
    content: none

table
    border-collapse: collapse
    border-spacing: 0

input
    -webkit-appearance: none
    border-radius: 0

body
    background: $color-white
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    color: $color-black
    display: flex
    justify-content: center
    width: 100%
    margin: 0
    padding: 0
    font-size: 24px
    touch-action: pan-x pan-y
#content
    max-width: 2000px
    width: 100%

a, a:hover, a:visited, a:active
    outline: none
    text-decoration: none
    color: initial
h1
    display: block
    font-size: 64px
    font-weight: 700
    line-height: 72px
    margin-bottom: 24px
    color: $color-white
    z-index: 1
    +tab
        font-size: 64px
        line-height: 72px
    +mob
        font-size: 32px
        line-height: 40px
        margin-bottom: 20px
h2
    display: block
    font-size: 64px
    font-weight: 700
    line-height: 72px
    margin-bottom: 24px
    color: $color-brand
    z-index: 1
    +tab
        font-size: 74px
        line-height: 80px
    +mob
        font-size: 32px
        line-height: 40px
        margin-bottom: 10px

h3
    display: block
    font-size: 32px
    font-weight: 700
    line-height: 40px
    color: $color-brand
    +tab
        font-size: 24px
        line-height: 32px
    +mob
        font-size: 20px
        line-height: normal
span
    font-size: 24px
    font-weight: 500
    line-height: 40px
    display: block
    +mob
        font-size: 16px
        line-height: 24px
    &.small
        font-size: 20px
        line-height: 32px
        +mob
            font-size: 16px
            line-height: 24px
p
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: $color-black
    +tab
        font-size: 14px
        line-height: 20px

@import 'animations'
@import 'components/block'
@import 'components/panel'
@import 'components/badge'
@import 'components/form'
@import 'components/header'
@import 'components/footer'
@import 'components/button'
@import 'main'
@import 'status'
@import '404'